@use '../../styles/variables' as *;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.accountMenu {
	background-color: rgba($color: $primary_1_50, $alpha: 0.85);
	box-shadow: 0 2px 1px #35468f;
	border-radius: 30px;
	height: 100%;
	overflow: auto;
	padding: 135px 15px 30px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 30px;
	grid-area: menu;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	transform: translateX(-100%);
	transition: 0.3s;
	overflow: initial;
	z-index: 1000;

	&.open {
		transform: translateX(0);
		background-color: rgba($color: $primary_1_50, $alpha: 1);
		border-radius: 0;
		width: 100%;
		overflow: auto;
	}

	&::-webkit-scrollbar {
		width: 0;
	}
}
.logo {
	text-align: center;
}
.user {
	background: #3f3f3f;
	border-radius: 10px;
	padding: 15px;
	gap: 15px;
	display: flex;
	align-items: center;
	font-family: $font-2, serif;
	font-weight: 600;
	font-size: 20px;
	color: #ffffff;
	cursor: pointer;

	span {
		display: block;
		font-weight: 400;
		font-size: 16px;
	}

	.avatar {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		overflow: hidden;
		background: #d9d9d9;
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.menu {
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 15px;
	.item {
		padding: 15px;
		border-radius: 42.5px;
		position: relative;
		display: flex;
		align-items: center;
		font-family: $font-2, serif;
		font-weight: 500;
		font-size: $p3_size;
		color: #ffffff;
		transition: 0.3s;
		text-transform: uppercase;
		.iconWrapper {
			display: flex;
			align-items: center;
			margin-right: 20px;
			i {
				width: 30px;
				height: 30px;
			}
		}
		span {
			position: relative;
		}
		&.active,
		&:hover {
			background-color: #000d66;
			span {
				color: $secondary_3;
			}
			color: $secondary_3;
			i {
				background-color: $secondary_3;
			}
		}
	}
}

.footer {
	display: flex;
	margin-top: auto;
	flex-direction: column;
	text-align: center;
	padding: 0 20px;
}

.socials {
	align-self: center;
	margin-bottom: 20px;
}

.copyright {
	// grid-area: copyright;
	line-height: 25px;
	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 4;
	@media (max-width: 1150px) {
		margin-top: 20px;
	}
	@media (max-width: 767px) {
		margin-top: 0px;
	}
}

@media (min-width: 1150px) {
	.accountMenu {
		position: relative;
		transform: none;
		z-index: auto;
		margin-right: 35px;
		padding-top: 35px;
	}
	.menu {
		width: 60px;
		.iconWrapper {
			margin-right: 0;
		}
		span {
			// display: none;
			position: absolute;
		}
	}
	.footer {
		display: none;
	}
}

@media (min-width: 1150px) and (max-width: 1919px) {
	.menu {
		.item {
			span {
				position: absolute;
				display: none;
				font-size: 12px;
				left: 70px;
				padding: 0 20px;
				height: 46px;
				border-radius: 23px;
				justify-content: center;
				align-items: center;
				white-space: nowrap;
				background-color: #000639;
				box-shadow: 1px 1px 2px #35468f;
				z-index: 1;
				&::before,
				&::after {
					content: '';
					position: absolute;
					width: 40px;
					height: 40px;
					background-color: $primary_1;
					mask-image: linear-gradient(to top, red, red),
						radial-gradient(circle 25px at center, green 80%, transparent 81%);
					mask-size: 21px 21px, 100%;
					mask-repeat: no-repeat, repeat;
					mask-composite: subtract;
				}
				&::before {
					bottom: 50%;
				}
				&::after {
					top: 50%;
				}
				&::before,
				&::after {
					left: -35px;
				}
				&::before {
					mask-position: bottom right, center;
				}
				&::after {
					mask-position: top right, center;
				}
			}
			&:hover {
				span {
					display: flex;
				}
			}
		}
	}
}

@media (min-width: 1920px) {
	.menu {
		width: auto;
		.item {
			padding: 15px 20px;
			span {
				display: block;
				position: relative;
			}
			.iconWrapper {
				margin-right: 15px;
			}
		}
	}
	.footer {
		display: flex;
	}
}

.btn_support {
	padding: 15px;
	border-radius: 42.5px;
	position: relative;
	display: flex;
	align-items: center;
	font-family: $font-2, serif;
	font-weight: 500;
	font-size: $p3_size;
	color: #ffffff;
	transition: 0.3s;
	text-transform: uppercase;
	width: 100%;
	background: none;
	border: none;
	@media (max-width: 1049px) {
		gap: 0px;
	}
	&:hover {
		background-color: #000d66;
		.text_support {
			color: $secondary_3;
		}
	}
}

.img_support {
	width: 25px;
	height: 25px;
	margin-left: 10px;
	transition: filter 0.3s;
	@media (max-width: 1919px) {
		margin-left: 5px;
	}
}

.text_support {
	margin-left: 50px;
	transition: color 0.3s;
	@media (max-width: 1919px) {
		display: none;
	}
	@media (max-width: 1149px) {
		display: block;
		margin-left: 22px;
	}
}
