@import "./../../../styles/variables";

.wrapper {
    background-image: url(../../../assets/img/jpg/faq_bg.jpg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    padding-bottom: 65px;
}
.title {
    margin-bottom: 40px;
}

.questionsList {
    display: flex;
    flex-direction: column;
}

.col {
    flex-basis: 50%;
}

.question {
    height: 40px;
    padding-left: 40px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    background-color: $primary_2;
    display: flex;
    transition: box-shadow 0.3s ease-in;
    &.open {
        box-shadow: 0 0 20px $secondary_3;
        margin-bottom: 0px;

        filter: drop-shadow(0 0 20px $secondary_3);
    }
}

.questionTitle {
    font-size: 16px;
    line-height: 1;
    font-weight: 150;
    max-width: 85%;
}

.btn {
    background-color: transparent;
    border: none;
}

.icon {
    width: 100%;
    height: 100%;
}

.text {
    display: none;
    &.open {
        display: block;
        padding: 35px 40px;
    }
}

.p {
    font-size: 15px;
    line-height: 1.3;
    color: $text_2;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

@media (min-width: 750px) {
    .wrapper {
        padding-bottom: 75px;
    }
    .questionsList {
        flex-direction: row;
        gap: 15px;
    }
}

@media (min-width: 1150px) {
    .wrapper {
        padding-bottom: 85px;
    }
    .title {
        margin-bottom: 70px;
    }
    .questionsList {
        gap: 55px;
    }
}

@media (min-width: 1400px) {
    .wrapper {
        padding-bottom: 145px;
    }
    .questionsList {
        gap: 60px;
    }
}
