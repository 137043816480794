@use "variables" as *;
@import "bootstrap/scss/bootstrap-grid";
@import "fonts";
@import "./cyborg";
@import "./globus";

body {
    font-family: $font-1, sans-serif;
    margin: 0;
    background-color: $primary_1;
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: $secondary_3;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
        background: #212f6c;
        border-radius: 3px;
    }
}

h1,
.h1 {
    font-weight: 700;
    font-size: 45px;
    margin: 30px 0;
    @include media-breakpoint-down(lg) {
        font-size: 40px;
    }
    @include media-breakpoint-down(md) {
        font-size: 35px;
        &-small {
            font-size: 28px;
        }
    }
}
h2,
.h2 {
    //font-weight: 700;
    font-size: 35px;
}
h3,
.h3,
p,
li {
    font-size: 25px;
    line-height: 1.3;
    @include media-breakpoint-down(md) {
        font-size: 16px;
    }
}
h4,
.h4 {
    font-size: 20px;
}
a {
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}

ul {
    padding-left: 18px;
}

.m-20 {
    margin: 20px 0;
}

.mb-20 {
    margin-bottom: 20px;
}

.fs-16 {
    font-size: 16px;
}

.link {
    font-size: 30px;
    font-weight: 500;
    color: white;
    border-bottom: solid 1px $blue-2;
    display: inline-block;
    margin-top: 20px;
    &:after {
        width: 36px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        position: absolute;
    }
    &:hover {
        &:after {
            background-color: $blue-2;
        }
    }
    @include media-breakpoint-down(md) {
        font-size: 25px;
        &:after {
            transform: translateY(-30%) scale(0.8);
        }
    }
}

img {
    max-width: 100%;
}

.text {
    &-center {
        text-align: center;
    }
    &-right {
        text-align: right;
    }
    &-left {
        text-align: left;
    }
    &-bold {
        font-weight: 500;
    }
    &-uppercase {
        text-transform: uppercase;
    }
    &-underline {
        text-decoration: underline;
        text-underline-offset: 4px;
    }
    &-green {
        color: $blue-1;
    }
    &-red {
        color: $red-1;
    }
}

.rangeslider {
    &.rangeslider-horizontal {
        margin-top: 50px;
        .rangeslider__fill {
            background-color: $secondary_3;
            background-image: linear-gradient(90deg, $secondary_3 0%, $secondary_3_50 100%);
        }
        .rangeslider__handle {
            background-color: $secondary_3;
            // border-color: $blue-4;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:after {
                width: 6px;
                height: 6px;
                top: 6.5px;
                left: 6.5px;
                margin: auto;
                background-color: #ffffff;
            }
            &-label {
                background-color: transparent;
                z-index: 9999;
            }
            &-tooltip {
                background-color: transparent;
                font-size: 12px;
                padding: 0;
                margin: 0;
                top: -40px;
                &:after {
                    display: none;
                }
            }
        }
    }
}

.error {
    background: #d52b1e;
    border-radius: 10px;
    font-size: 20px;
    align-items: center;
    color: #ffffff;
    padding: 20px;
    margin: 20px 0;
}

.PhoneInputCountry {
    background-color: transparent !important;
}

.PhoneInputCountrySelect {
    background-color: $primary_3 !important;

    option {
        font-family: "AbitareSans";
        font-weight: 150;
        &:checked {
            background-color: $secondary_3;
        }
    }
}

.PhoneInput {
    // background-color: rgba(215, 215, 215, 0.6);
    // border-radius: 10px;
    // display: flex;
    // align-items: center;
    // padding: 0 12px;
    // gap: 6px;
    // margin-bottom: 20px;

    input {
        border: none !important;
        background: none !important;
        outline: none !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        z-index: 999;
        font-size: 15px;
        font-weight: 150;
        line-height: 22px;
        color: $text_1;
        text-align: left;
        background-color: inherit;
        border: none;
        outline: 0px solid transparent;
        &:focus {
            outline: 0px solid transparent;
        }
        &::placeholder {
            font-weight: 150;
            color: $text_2;
            position: absolute;
            z-index: 100;
        }
    }

    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;
    height: 48px;
    overflow: hidden;
    font-size: 15px;
    font-weight: 150;
    line-height: 22px;
    color: $text_1;
    text-align: left;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
    padding: 12px 24px;
    border-radius: 24px;
    border: 1px solid $input_border;
    background-color: $input;
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
}

@media (min-width: 1920px) {
    .PhoneInput {
        margin-bottom: 0;
    }
}

.PhoneInputLabel {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    @media (max-width: 1160px) and (min-width: 992px) {
        font-size: 16px;
        white-space: nowrap;
    }
}

// SAIRUS NEW DESIGN STYLES

@import "./variables";
@import "./fonts";

.App {
    background-color: $primary_1;
    overflow-x: hidden;
}

*,
*::after,
*::before {
    font-family: "AbitareSans";
    color: $text_1;
}

a,
li,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1;
}

h3 {
    font-size: $h3_size;
    line-height: 1.4;
    font-weight: 150;
}

p {
    line-height: 1.47;
    font-weight: 150;
}

.p1 {
    font-size: $p1_size;
}

.p2 {
    font-size: $p2_size;
}

.p3 {
    font-size: $p3_size;
}

a {
    text-decoration: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}

ul {
    list-style: none;
}

button {
    cursor: pointer;
}

.ml-auto {
    margin-left: auto;
}

.gray {
    color: $text_2;
}

.pink {
    color: $secondary_1;
}

.bold {
    font-weight: 500;
}

.rangeslider.rangeslider-horizontal {
    margin-bottom: 15px;
    background-color: $input;
}

.rangeslider.rangeslider-horizontal .rangeslider__fill {
    background-image: none;
}

.magenta.rangeslider {
    .rangeslider__fill {
        background-color: $secondary_1;
    }
}

.purple.rangeslider {
    .rangeslider__fill {
        background-color: $secondary_2;
    }
}

.cian.rangeslider {
    .rangeslider__fill {
        background-color: $secondary_3;
    }
}

.magenta.rangeslider.rangeslider-horizontal .rangeslider__handle {
    background-color: $secondary_1;
    box-shadow: 0 0 5px $secondary_1;

    box-shadow: 0 0 12px 12px rgba($secondary_1, 0.4);
    border-color: transparent;

    &-tooltip {
        * {
            color: $secondary_1;
        }
    }
    // filter: drop-shadow(0 0 20px $secondary_1);
}

.purple.rangeslider.rangeslider-horizontal .rangeslider__handle {
    background-color: $secondary_2;
    box-shadow: 0 0 5px $secondary_2;

    box-shadow: 0 0 12px 12px rgba($secondary_2, 0.4);
    border-color: transparent;

    &-tooltip {
        * {
            color: $secondary_2;
        }
    }

    // filter: drop-shadow(0 0 20px $secondary_2);
}

.cian.rangeslider.rangeslider-horizontal .rangeslider__handle {
    background-color: $secondary_3;
    box-shadow: 0 0 5px $secondary_3;

    box-shadow: 0 0 12px 12px rgba($secondary_3, 0.4);
    border-color: transparent;

    &-tooltip {
        * {
            color: $secondary_3;
        }
    }
    // filter: drop-shadow(0 0 20px $secondary_3);
}

.text-gradient {
    background-image: linear-gradient(270deg, #8700fd 17%, #0066ff 52%, #f728f7 88%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: 500;
}

.text-magenta {
    color: $secondary_1;
}

.text-blue {
    color: $secondary_3;
}

.loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: lightcoral;
    display: none;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 10000;
    &.open {
        display: flex;
    }
}
