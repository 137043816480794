@import "../../styles/variables";

.title {
    font-size: 25px;
    font-weight: 150;
    margin-bottom: 30px;
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "header" "content" "footer";
    background-color: $primary_1;
    min-height: 100vh;
}

.contentWrapper {
    grid-area: content;
    padding: 10px 0;
    overflow-y: hidden;
    background-color: rgba(#000554, 0.77);
    box-shadow: 0 2px 1px #35468f;
    position: relative;
}

.content {
    height: 100%;
    width: 100%;
    overflow-x: clip;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}

.footer {
    grid-area: footer;
    padding: 35px 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.socials {
    margin-bottom: 30px;
}

.successfully {
    padding: 40px 20px;
    text-align: center;
    font-weight: 150;
    font-size: $h3_size;
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    a {
        color: $secondary_3;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    img {
        width: 30px;
        height: 30px;
    }
}

@media (min-width: 1150px) {
    .title {
        font-size: 35px;
        margin-bottom: 40px;
    }
    .wrapper {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: "header header" "menu content";
        height: 100vh;
        padding: 15px 50px 50px;
        background-image: url(../../assets/img/jpg/cabinet_bg.png);
        background-size: 100%;
        background-position: top center;
    }
    .contentWrapper {
        border-radius: 30px;
        padding: 30px 10px 60px;
    }
    .content {
        overflow-y: auto;
    }
    .footer {
        display: none;
    }
}

@media (min-width: 1400px) {
    .contentWrapper {
        padding: 40px 20px 60px;
    }
}

@media (min-width: 1920px) {
    .wrapper {
        grid-template-columns: 364px 1fr;
    }
    .contentWrapper {
        padding: 45px 40px 60px;
    }
}
