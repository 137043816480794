@use "../../styles/variables" as *;
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9001;
    display: flex;
    align-items: center;
    justify-content: center;
}
.backDrop {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    //background: #00000080;
    backdrop-filter: blur(2px) brightness(0.5);
}
.card {
    // min-width: 600px;
    width: 95vw;
    max-width: 95vw;
    background-color: rgba($color: $primary_1, $alpha: 1);
    & > div {
        padding: 0;
    }
}
.modal {
    position: relative;
    z-index: 2;
    background: #ffffff;
    border-radius: 15px;
    overflow: hidden;
    max-width: 95%;
}

.header {
    background: $blue-1;
    padding: 20px 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @include media-breakpoint-down(md) {
        padding: 18px;
    }
}

.title {
    font-size: 25px;
    font-weight: 600;
    color: #ffffff;

    @include media-breakpoint-down(md) {
        font-size: 20px;
        font-weight: 500;
    }
}

.close {
    position: absolute !important;
    top: 14px;
    right: 16px;
    transform: rotate(45deg);
    z-index: 5001;
    i {
        background-color: $secondary_3;
        width: 40px !important;
    }
}

.content {
    padding: 17px;
    min-height: 100px;
    max-height: 88vh;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media (min-width: 1150px) {
    .card {
        width: fit-content;
        min-width: 600px;
    }
    .content {
        padding: 40px;
    }
}
