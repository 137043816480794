@import "../../styles/variables";

.removeWallet {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    button {
        height: 48px;
        width: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.title {
    font-weight: 150;
    font-size: $h3_size;
    margin-top: 20px;
    margin-bottom: 30px;
}
.text {
    font-weight: 150;
    font-size: $h5_size;
}
.textField {
    align-self: center;
    max-width: 420px;
    width: 100%;
    margin: 15px 0 40px;
    i {
        background-color: $secondary_3;
    }
    input {
        width: 100%;
        font-size: 15px;
        font-weight: 400;
    }
    * {
        max-width: none !important;
    }
}
