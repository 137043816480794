@import '../../../styles/variables';
.wrapper {
	display: flex;
	padding-top: 25px;
	position: relative;
	z-index: 99;
}

.container {
	max-width: 1920px;
}

.menu {
	position: absolute;
	display: flex;
	flex: 1;
	justify-content: flex-end;
	width: 100vw;
	padding-right: 11%;
	max-width: 1920px;
}

.menuBtn {
	display: none;
}

.buttons {
	order: 0;
	margin: 0;
}

.buttons {
	order: 1;
	margin-top: 10px;
	display: flex;
}

@media (max-width: 1800px) {
	.menu {
		margin-right: 70px;
	}
}

@media (max-width: 1680px) {
	.menu {
		margin-right: 10px;
	}
}

@media (max-width: 1580px) {
	.menu {
		margin-right: 10px;
	}
}

@media (max-width: 1149px) {
	.wrapper {
		top: 0;
		left: 0;
		padding: 0;
		margin-top: 20px;
		margin-right: -70px;
		z-index: 9001;
		transition: all 0.2s ease-in;
	}

	.menu {
		position: relative;
	}

	.menuBtn {
		display: block;
		position: relative;
		right: 40px;
	}
}
