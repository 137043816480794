@use "../../styles/variables" as *;
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.form {
    background: rgba(255, 255, 255, 0.57);
    backdrop-filter: blur(17.5px);
    border-radius: 25px;
    padding: 25px;
    position: relative;
    @include media-breakpoint-down(md) {
        .h1 {
            text-align: center;
        }
    }
}

.accept {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        + label {
            &:before {
                content: "";
                display: inline-block;
                width: 22px;
                height: 22px;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1px solid #000000;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50% 50%;
                border-radius: 5px;
            }
            &.error {
                &:before {
                    border-color: $red;
                }
                + span {
                    text-decoration: underline;
                    text-decoration-color: $red;
                }
            }
        }
        &:checked + label::before {
            border-color: $blue-1;
            background-color: $blue-1;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
            box-shadow: 0 3px 5px rgba(46, 46, 66, 0.08);
        }
        &:not(:disabled):not(:checked) {
            + label:hover::before {
                border-color: $blue-1;
            }
        }
        &:not(:disabled):active {
            + label::before {
                background-color: $blue-1;
                border-color: $blue-1;
            }
        }
    }
    span {
        font-size: $p3_size;
        font-weight: 150;
        color: $text_2;
        span {
            cursor: pointer;
            text-decoration: underline;
            text-underline-offset: 3px;
            color: $secondary_3;
        }
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 30px;
    }
}
.linkWrapper {
    margin-top: 20px;
}
.link {
    font-weight: 400;
    font-size: $p2_size;
    line-height: 1;
    text-decoration-line: underline;
    color: $secondary_1;
    display: inline-block;
    &.cian {
        color: $secondary_3;
    }
}

.bottom {
    margin-top: 60px;
    margin-bottom: 30px;
    text-align: center;
}

@media (min-width: 1150px) {
    .bottom {
        margin-bottom: 40px;
    }
}
@media (min-width: 1400px) {
    .bottom {
        margin-bottom: 60px;
        &.cian {
            margin-top: 50px;
            margin-bottom: 20px;
        }
    }
}

.formContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 50px;
    padding-bottom: 50px;
    &.magenta {
        padding-bottom: 90px;
    }
}

@media (min-width: 1150px) {
    .formContent {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.captcha {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.magenta {
    .captcha {
        height: auto;
        align-items: center;
        margin-top: 25px;
        margin-bottom: -50px;
    }
}

@media (min-width: 750px) {
    .magenta {
        .captcha {
            margin-bottom: -70px;
        }
    }
}
