@import '../../styles/colors';

.button {
	padding: 20px 35px;
	border: none;
	max-height: 60px;
	background-color: transparent;
	border-radius: 30px;
	box-shadow: 0 0 5px 5px $secondary_3;
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
	color: $text_1;
	transition: all 0.2s ease;
	position: relative;
	&:disabled {
		color: $text_2;
	}
	&:hover {
		background-color: $secondary_3;
		box-shadow: none;
		cursor: pointer;
		transition: all 0.2s ease;
	}
	&__star {
		position: absolute;
		top: -5px;
		right: 20px;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		background: rgb(255, 255, 255);
		background: radial-gradient(
			circle,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 1) 5%,
			$secondary_3 50%
		);
		box-shadow: 0 0 2px 2px rgba($secondary_3, 1);
		animation: anim-glow 1s ease infinite;
		animation-direction: alternate;
	}
}

@mixin painted_button($color, $color_50) {
	box-shadow: 0 0 5px 5px $color;
	&:hover {
		background-color: $color;
		box-shadow: none;
		cursor: pointer;
		transition: all 0.2s ease;
	}
	.button__star {
		background: radial-gradient(
			circle,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 1) 5%,
			$color 50%
		);
		box-shadow: 0 0 5px 5px rgba($color, 1);
	}
}

.button_magenta {
	@include painted_button($secondary_1, $secondary_1_50);
	& .button__star {
		animation: anim-glow 1s ease infinite;
		animation-direction: alternate;
	}
}

.button_purple {
	@include painted_button($secondary_2, $secondary_2_50);
	& .button__star {
		animation: anim-glow 1s ease infinite;
		animation-direction: alternate;
	}
}

.button_cian {
	@include painted_button($secondary_3, $secondary_3_50);
	& .button__star {
		animation: anim-glow 1s ease infinite;
		animation-direction: alternate;
	}
}

@keyframes anim-glow {
	100% {
		box-shadow: 0 0 2px 2px transparent;
	}
}
