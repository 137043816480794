@import "../../styles/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.transfer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
        height: 48px;
        width: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.textField {
    align-self: center;
    max-width: 330px;
    width: 100%;
    margin: 25px 0 40px;
    i {
        background-color: $secondary_3;
    }
    input {
        width: 100%;
        font-size: 15px;
        font-weight: 400;
    }
    * {
        max-width: none !important;
    }
}
.user {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: "text change" "name name" "email email";
    gap: 20px;
    background: #071163;
    border-radius: 21px;
    padding: 20px 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    * {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 150;
    }

    .text {
        grid-area: text;
    }

    .name {
        grid-area: name;
        text-transform: uppercase;
        div {
            font-weight: 500;
        }
    }

    .email {
        grid-area: email;
        div {
            color: $text_2;
        }
    }

    .change {
        grid-area: change;
        text-decoration-line: underline;
        text-underline-offset: 4px;
        color: $secondary_3;
        cursor: pointer;
        margin-left: auto;
    }
}

.iconWrapper {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-color: $primary_1;
    i {
        background-color: $secondary_3;
        width: 30px;
        height: 30px;
    }
    &.chatIcon {
        margin-right: 0;
        i {
            background-color: $secondary_1;
        }
    }
}

.flexbox {
    align-self: stretch;
    display: flex;
    gap: 15px;
    align-items: stretch;
    justify-content: space-between;
    & > * {
        flex-basis: 50%;
    }
    &.amount {
        align-items: flex-end;
    }
}
.amountText {
    text-align: center;
    font-size: $h5_size;
    font-weight: 150;
    margin-bottom: 15px;
}
.finish {
    font-weight: 150;
    font-size: $h4_size;
    color: #ffffff;
    margin: 0 auto;
    padding: 12px 24px;
    border-radius: 24px;
    border: 1px solid $input_border;
    background-color: $input;
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    span:first-child {
        margin-right: 5px;
        margin-left: 5px;
        font-weight: 500;
        color: $secondary_3;
    }
    span:last-child {
        margin-right: 5px;
        margin-left: 5px;
        font-weight: 500;
        color: $secondary_3;
        text-transform: uppercase;
    }
}

.successTitle {
    font-size: $h3_size;
    font-weight: 150;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.successText {
    font-size: $h4_size;
    font-weight: 150;
    margin-bottom: 20px;
    line-height: 1.2;
    text-align: center;
}

.backWrapper {
    margin: 20px 0;
    position: relative;
    z-index: 5001px;
}

@media (min-width: 1150px) {
    .backWrapper {
        position: static;
    }
    .successTitle {
        margin-bottom: 40px;
    }
}

.link {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: $h5_size;
    z-index: 1;
    i {
        font-style: normal;
        text-decoration: none;
        filter: drop-shadow(0 0 2px white);
    }
    b {
        text-decoration: underline;
        font-weight: 150;
        color: $secondary_3;
    }
    &.back {
        i {
            margin-right: 10px;
        }
        @include media-breakpoint-up(md) {
            font-size: 18px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &.history {
        margin-bottom: 40px;
        i {
            margin-left: 10px;
        }
    }
}

.error {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
    transition: all 0.1s ease-in;
    margin: 0;
    padding: 0;
    &.show {
        opacity: 1;
        transform: scaleY(1);
        visibility: visible;
        margin-top: -25px;
        margin-bottom: 15px;
        padding: 20px;
    }
}
.acceptedBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 40px;
    button {
        align-self: center;
    }
}

@media (min-width: 750px) {
    .user {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: "text text change" "name email email";
    }
}
