@use "../../../styles/variables";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import "../../../styles/colors";
@import "../../../styles/icons";
@import "../../../styles/mixins";
@import "../../../styles/variables";
@import "../../../styles/variables_new";

.accountHeaderMenu {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 30px;
    font-size: 13px;
    line-height: 18px;
    &.menuOpen {
        margin-top: 120px;
        z-index: 0;
    }
    @media (min-width: 1150px) and (max-width: 1199.5px) {
        width: 950px;
        max-width: 950px;
    }
    @media (max-width: 1150px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-auto-flow: row;
        gap: 10px;
        justify-content: stretch;
        grid-template-areas:
            "balance user user user"
            "replenish withdraw transfer wallet";
        width: 100%;
        max-width: unset;
    }
    @media (max-width: 750px) {
        grid-template-columns: calc(50% - 5px) calc(50% - 5px);
        grid-template-areas:
            "balance user"
            "replenish withdraw"
            "transfer wallet";
    }
}

.menuItem {
    box-sizing: content-box;
    height: 60px;
    border-radius: 30px;
    border-left: 1px solid $secondary_3;
    width: 20%;
    display: flex;
    gap: 15px;
    // justify-content: space-between;
    // gap: -50px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    padding: 0 55px 0 25px;
    margin-right: -50px;
    z-index: 1000;
    background: $primary_4;
    transition: all 0.1s ease-in-out;
    z-index: 1000;

    @media (max-width: 1399.5px) {
        gap: 10px;
        padding: 0 55px 0 15px;
    }

    &:nth-child(1) {
        background: $primary_2;
        border-left: none;
        padding: 0 55px 0 15px;
        display: flex;
        gap: 10px;
        align-items: center;
        @media (max-width: 1150px) {
            grid-area: balance;
        }
    }
    &:nth-child(2) {
        color: $secondary_1;
        & span,
        & div {
            color: $secondary_1;
        }
        & i {
            background-color: $secondary_1;
        }
        @media (max-width: 1150px) {
            grid-area: replenish;
        }
    }
    &:nth-child(3) {
        color: $secondary_2;
        & span,
        & div {
            color: $secondary_2;
        }
        & i {
            background-color: $secondary_2;
        }
        @media (max-width: 1150px) {
            grid-area: withdraw;
        }
    }
    &:nth-child(4) {
        color: $secondary_3;
        & span,
        & div {
            color: $secondary_3;
        }
        & i {
            background-color: $secondary_3;
        }
        @media (max-width: 1150px) {
            grid-area: transfer;
        }
    }
    &:nth-child(5) {
        color: $secondary_4;
        & span,
        & div {
            color: $secondary_4;
        }
        & i {
            background-color: $secondary_4;
        }
        @media (max-width: 1150px) {
            grid-area: wallet;
        }
    }
    &:nth-child(6) {
        z-index: 5000;
        width: 30%;
        // border-right: 1px solid $secondary_3;
        border: none;
        background: $primary_2;
        position: relative;
        @media (max-width: 1399.5px) {
            width: 20%;
            padding: 0 15px;
        }
        @media (max-width: 1150px) {
            grid-area: user;
            width: 100%;
        }
        &:hover .userControls {
            transition: all 0.1s ease-in-out;
            background: $primary_3;
        }
    }
    &:nth-child(7) {
        margin-right: 0;
        justify-content: flex-end;
        padding: 0 25px;
        width: 15%;
        @media (max-width: 1399.5px) {
            padding: 0 15px;
        }
        @media (max-width: 1150px) {
            display: none;
        }
    }
    @media (max-width: 1199.5px) {
        width: 150px;
    }
    &:hover {
        background: $primary_3;
        // background-image: linear-gradient(90deg, $secondary_2 0%, $primary_2 90%);
        cursor: pointer;
        transition: all 0.1s ease-in-out;
    }
    @media (max-width: 1150px) {
        position: unset;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding: 15px;
        grid-auto-flow: row;
        border-right: 1px solid $secondary_3;
        border-left: none;
    }
}

.buttonControlsExpand {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    justify-self: flex-end;
    margin-left: auto;
    background-color: $primary_1;
    z-index: 5000;
    transition: all 0.1s ease-in-out;
    & i {
        width: 8px;
        height: 8px;
        margin-top: 1px;
    }
    &.expand {
        transform: rotate(180deg);
        transition: all 0.1s ease-in-out;
    }
}

.userControls {
    position: absolute;
    width: calc(100% + 2px);
    height: 0px;
    opacity: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    // border-bottom: 1px solid $secondary_3;
    // border-left: 1px solid $secondary_3;
    // border-right: 1px solid $secondary_3;
    top: 30px;
    left: -1px;
    background: $primary_2;
    z-index: 4000;
    padding: 20px 15px 5px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    visibility: hidden;
    // transition: opacity 0.3s ease-in-out 0.1s, height 0.1s ease-in-out, background-color 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    @media (min-width: 1400px) {
        padding: 20px 55px 5px 30px;
    }
    &:hover {
        // transition: opacity 0.3s ease-in-out 0.1s, height 0.1s ease-in-out, background-color 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
        background: $primary_3;
    }
    &.expand {
        height: 80px;
        opacity: 1;
        transition: all 0.1s ease-in-out;
        visibility: visible;
        // transition: opacity 0.3s ease-in-out 0.1s, height 0.1s ease-in-out;
    }
}

.currentRank {
    border: 1px solid $secondary_3;
    padding: 0 5px;
    border-radius: 3px;
    transition: all 0.1s ease-in;
    position: relative;
    &:hover {
        background-color: $secondary_3;
    }
}

.hint {
    position: absolute;
    top: calc(100% + 10px);
    left: -50%;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s ease-in;
    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.messenger {
    padding: 8px;
    box-shadow: 0 0 5px 5px $secondary_1;
    i {
        background-color: $secondary_1;
    }
    @media (max-width: 1399.5px) {
        transform: scale(0.8);
    }
}

.iconWrapper {
    display: flex;
    align-items: center;
    @media (max-width: 1399.5px) {
        transform: scale(0.8);
    }
}

.textWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.balanceValue {
    font-size: 10px;
    white-space: nowrap;
}

%refresh {
    height: 35px;
    display: inline-block;
    margin: 0;
    cursor: pointer;
    // transform: rotate(0deg);
    // transition: transform 0.6s ease-in;
    @media screen and (max-width: 768px) {
        height: 30px;
    }
    @media screen and (max-width: 480px) {
        height: 25px;
    }
}

.refresh {
    @extend %refresh;
}

.refreshPending {
    @extend %refresh;
    animation: refreshAnimation 1s linear infinite;
    // transform: rotate(360deg);
    // transition: transform 6s linear infinite;
}

@keyframes refreshAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    background: #d9d9d9;
    display: flex;
    justify-content: center;
    z-index: 5000;
    align-items: center;
    @media screen and (max-width: 768px) {
        width: 30px;
        height: 30px;
    }
    @media screen and (max-width: 480px) {
        width: 25px;
        height: 25px;
    }
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.user {
    padding: 0 15px;
}

.userInfo {
    z-index: 5000;
    text-align: left;
    // @media (max-width: 1399.5px) and (min-width: 1150px) {
    //     display: none;
    // }
}

.email {
    font-size: 10px;
    z-index: 5000;
    text-transform: none;
    @media (max-width: 1149px) {
        display: none;
    }
}

.logout {
    background-color: $primary_1;
    box-shadow: none;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    i {
        background-color: $secondary_3;
        width: 20px;
        height: 20px;
    }
}

.lang {
    transform: scale(0.75);
}
