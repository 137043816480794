@use '../../styles/variables' as *;

.switcher {
	display: flex;
	align-items: center;
	padding: 8px;
	gap: 8px;
	border-radius: 20px;
	background: #06060700;
	font-size: 18px;
	color: #000000;
	width: max-content;
	position: relative;
	white-space: nowrap;
	cursor: pointer;
	min-width: 100px;
	&.open {
		// background-color: $blue-1;
		color: #ffffff;
	}
	@media (max-width: 450px) {
		min-width: 0;
		width: 75px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.flag {
	max-height: 24px;
	border-radius: 50%;
}

.label {
	font-size: 15px;
	line-height: 2.3;
}

.list {
	position: absolute;
	top: 100%;
	left: 0;
	background: #06060700;
	// box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 10px 15px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: max-content;
	z-index: -2;
	height: 0;
	opacity: 0;
	transition: opacity 0.3s;
	overflow: hidden;
	&.top {
		top: unset;
		bottom: 100%;
	}
	&.open {
		height: unset;
		z-index: 955;
		opacity: 1;
	}
}
.item {
	display: flex;
	align-items: center;
	gap: 7px;
	font-size: 15px;
	white-space: nowrap;
	color: #000000;
	// .flag {
	//     width: 30px;
	// }
}
