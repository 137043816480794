$primary_1: #000741;
$primary_2: #001a65;
$primary_3: #002273;
$primary_4: #010955;

$primary_1_50: #00074177;
$primary_2_50: #001a6577;
$primary_3_50: #00227377;

$secondary_1: #f728f7;
$secondary_2: #8700fd;
$secondary_3: #0066ff;
$secondary_4: #00bbff;

$secondary_1_50: #f728f777;
$secondary_2_50: #8700fd77;
$secondary_3_50: #0066ff77;

$gradient_1: linear-gradient(#f728f7, #0066ff, #8700fd);
$gradient_1_90: linear-gradient(90deg, #f728f7, #0066ff, #8700fd);

$success_color: #57c26d;
$error_color: #df526d;

$text_1: #ffffff;
$text_2: #a3a8ce;

$input: #292a6d;
$input_disabled: #151939;
$input_border: #3d4375;
$input_success_error: #000228;
