.cyborg {
    position: fixed;
    top: calc(50% - 142px);
    right: 0;
    z-index: 9998;
    transition: right 2200ms linear;
    &.stop {
        right: -285px;
    }
    &__messages {
        position: absolute;
        right: 0;
        bottom: 270px;
        width: 250px;
        z-index: 1;
        span {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 10px 15px;
            font-size: 14px;
            line-height: 1.2;
            background: rgba(#001a65, 0.6);
            color: #fff;
            border-radius: 30px;
            font-weight: bold;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s;
            cursor: default;
            a {
                color: #fff;
                text-decoration: underline;
                cursor: pointer;
                z-index: 1000;
                &:hover {
                    text-decoration: none;
                    color: #00aaff;
                }
            }
        }
    }
    &.step1 .cyborg__text_1,
    &.step2 .cyborg__text_2,
    &.step3 .cyborg__text_3,
    &.step4 .cyborg__text_4,
    &.step5 .cyborg__text_5,
    &.step6 .cyborg__text_6 {
        opacity: 1;
        visibility: visible;
    }
}
