@use "../../styles/variables" as *;
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "../../styles/mixins";

.wallets {
    position: relative;
    border-radius: 15px;
    padding: 45px 20px;
    grid-area: b;
    min-height: 330px;
    background-color: #000741;
    box-shadow: 0 0 10px 3px $secondary_3;
    .star {
        position: absolute;
        z-index: 1;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        bottom: -21px;
        left: -18px;
        background: radial-gradient(
            circle,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 5%,
            $secondary_3 20%,
            $secondary_3_50 33%,
            rgba(255, 255, 255, 0) 50%
        );
    }
}

.title {
    font-size: $h3_size;
    font-weight: 150;
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    span {
        width: 150px;
    }
    .icon {
        width: 55px;
        height: 55px;
    }
}

.list {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 45px;

    //     .list {
    //     margin-top: 40px;
    //     display: flex;
    //     gap: 45px;
    //     flex-wrap: wrap;
    //     justify-content: center;
    // }
}

.wallet {
    display: flex;
    align-items: center;
    .walletIcon {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: 90%;
    }
    span {
        display: block;
    }
    .walletLabel {
        display: none;
    }
    .icon {
        display: none;
    }
    &.isSettings {
        padding: 10px 20px 10px 10px;
        background-color: #09156d;
        border-radius: 35px;
        .walletIcon {
            width: 40px;
            height: 40px;
        }
        .walletLabel {
            margin-left: 20px;
            display: block;
            text-transform: uppercase;
        }
        .icon {
            display: block;
            margin-left: auto;
            width: 30px;
            height: 30px;
        }
    }

    &.PAYEER {
        .walletIcon {
            box-shadow: 0 0 10px 3px $secondary_3;
            background-image: url(../../assets//img/jpg/payeer.png);
        }
    }
    &.PERFECT_MONEY {
        .walletIcon {
            box-shadow: 0 0 10px 3px #e6396a;
            background-image: url(../../assets//img/jpg/perfect_money.png);
        }
    }
    &.USDT {
        .walletIcon {
            box-shadow: 0 0 10px 3px #29d2d0;
            background-image: url(../../assets//img/jpg/trc20.png);
        }
    }
    cursor: pointer;
    &.disabled {
        filter: grayscale(1);
        opacity: 0.6;
        cursor: default;
    }
}

.plus {
    width: 55px;
    height: 55px;
    background: $secondary_3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
    }
}
.first {
    font-size: 17px;
    color: #ffffff;
    text-align: center;
}

@media (min-width: 750px) {
    .wallets {
        padding: 30px 20px;
        .star {
            width: 60px;
            height: 60px;
            bottom: -30px;
            left: -24px;
        }
    }
    .list {
        gap: 30px;
    }
}

@media (min-width: 1150px) {
    .wallets {
        padding: 40px;
        .star {
            width: 75px;
            height: 75px;
            bottom: -35px;
            left: -35px;
        }
    }
    .list {
        gap: 40px;
    }
}
