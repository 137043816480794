@import "../../../styles/variables";

.modalContent {
    min-width: 75vw;
    min-height: 65vh;
    max-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    iframe {
        width: 100%;
        height: calc(100% - 50px);
        position: absolute;
        top: 50px;
        left: 0;
    }
}
.confidential {
    padding: 40px 20px;
    font-weight: 150;
    h3 {
        font-weight: 500;
        margin-bottom: 50px;
        text-align: center;
        text-transform: uppercase;
    }
    p {
        margin-bottom: 20px;
    }
    ul {
        list-style: circle;
        padding-left: 20px;
        margin-bottom: 20px;
    }
}

.tech_works {
    padding-top: 20px;
    text-align: center;
    p:last-child {
        color: $secondary_3;
        font-weight: 500;
        margin-top: 20px;
    }
}
