@import "../../styles/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.addWallet {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    button {
        height: 48px;
        width: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.title {
    font-weight: 150;
    font-size: $h3_size;
    margin-top: 20px;
    margin-bottom: 30px;
}
.text {
    font-weight: 150;
    font-size: $h5_size;
}
.list {
    margin-top: 30px;
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    justify-content: center;
}
.textField {
    align-self: center;
    max-width: 420px;
    width: 100%;
    margin: 15px 0 40px;
    i {
        background-color: $secondary_3;
    }
    input {
        width: 100%;
        font-size: 15px;
        font-weight: 400;
    }
    * {
        max-width: none !important;
    }
}

.backWrapper {
    margin: 20px 0;
    position: relative;
    z-index: 5001px;
}

@media (min-width: 1150px) {
    .backWrapper {
        position: static;
    }
}

.link {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: $h5_size;
    z-index: 5001;
    margin: 10px 0;
    i {
        font-style: normal;
        text-decoration: none;
        filter: drop-shadow(0 0 2px white);
    }
    b {
        text-decoration: underline;
        font-weight: 150;
        color: $secondary_3;
    }
    &.back {
        i {
            margin-right: 10px;
        }
        @include media-breakpoint-up(md) {
            font-size: 18px;
            position: absolute;
            top: 10px;
            left: 10px;
        }
    }
    &.history {
        margin-bottom: 40px;
        i {
            margin-left: 10px;
        }
    }
}
