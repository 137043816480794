@import "../../../styles/colors";

.container {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.label {
    font-size: 17px;
    font-weight: 400;
    color: $text_1;
    &_small {
        font-size: 15px;
        font-weight: 150;
    }
}

.textfield {
    width: 100%;
    max-width: 268px;
    height: 48px;
    overflow: hidden;
    font-size: 15px;
    font-weight: 150;
    line-height: 22px;
    color: $text_1;
    text-align: left;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
    padding: 12px 24px;
    border-radius: 24px;
    border: 1px solid $input_border;
    background-color: $input;
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
    &_small {
        height: 36px;
        padding: 7px 24px;
        border-radius: 18px;
    }
    &_success {
        background-color: $input_success_error;
        border: 1px solid $success_color;
    }
    &_error {
        background-color: $input_success_error;
        border: 1px solid $error_color;
    }
    &_disabled {
        background-color: $input_disabled;
        border: 1px solid $input_border;
        color: $text_2;
    }
}

.textfield__text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    z-index: 999;
    font-size: 15px;
    font-weight: 150;
    line-height: 22px;
    color: $text_1;
    text-align: left;
    background-color: inherit;
    border: none;
    outline: 0px solid transparent;
    &:focus {
        outline: 0px solid transparent;
    }
    &::placeholder {
        font-weight: 150;
        color: $text_2;
        position: absolute;
        z-index: 100;
    }
}

.iconSuccess {
    background-color: $success_color;
}

.iconError {
    background-color: $error_color;
}
