@import './../../../styles/variables';
@import '../../../styles/colors';

.calculator {
	position: relative;
	border-radius: 28px;
}
.type {
	font-size: $h4_size;
	text-transform: uppercase;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
	margin: auto;
	left: 0;
	right: 0;
	text-align: center;
	width: fit-content;
	top: -12px;
	z-index: 9000;
	font-weight: 500;
	background-color: $primary_1;
	background: linear-gradient(
		to bottom,
		transparent 0%,
		$primary_1 40%,
		$primary_1 60%,
		transparent 100%
	);
	border-radius: 50px;
	.star {
		width: 10px;
		height: 10px;
		border-radius: 100%;
	}
	&_magenta {
		color: $secondary_1;
		.star {
			background: radial-gradient(
				circle,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 1) 5%,
				$secondary_1 50%
			);
			box-shadow: 0 0 5px 5px rgba($secondary_1, 1);
		}
	}
	&_purple {
		color: $secondary_2;
		.star {
			background: radial-gradient(
				circle,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 1) 5%,
				$secondary_2 50%
			);
			box-shadow: 0 0 5px 5px rgba($secondary_2, 1);
		}
	}
	&_cian {
		color: $secondary_3;
		.star {
			background: radial-gradient(
				circle,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 1) 5%,
				$secondary_3 50%
			);
			box-shadow: 0 0 5px 5px rgba($secondary_3, 1);
		}
	}
}

.wrapper {
	background-color: rgba($color: $primary_1, $alpha: 0.84);
	padding: 20px;
	padding-bottom: 8px;
	border-radius: 28px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 8000;
	max-height: 88vh;
	overflow-y: auto;
	&.withBtn {
		overflow-y: visible;
	}
	&_magenta {
		box-shadow: 0 0 2px 3px $secondary_1;
	}
	&_purple {
		box-shadow: 0 0 2px 3px $secondary_2;
	}
	&_cian {
		box-shadow: 0 0 2px 3px $secondary_3;
	}
}

@media (min-width: 750px) {
	.wrapper {
		padding: 28px;
	}
	.type {
		font-size: $h3_size;
		top: -18px;
	}
}

.calculator {
	.button {
		margin: auto;
		position: absolute;
		padding: 15px 20px;
		display: flex;
		justify-content: center;
		left: 0;
		right: 0;
		bottom: -22px;
		background-color: $primary_1;
		width: 180px;
		z-index: 9000;
	}
}

@media (min-width: 1150px) {
	.calculator {
		.button {
			width: 200px;
		}
	}
}

.button_magenta {
	box-shadow: 0 0 5px 5px $secondary_1;
}

.button_magenta:hover {
	background-color: $secondary_1;
}

.button_purple {
	box-shadow: 0 0 5px 5px $secondary_2;
}
.button_purple:hover {
	background-color: $secondary_2;
}

.button_cian {
	box-shadow: 0 0 5px 5px $secondary_3;
}
.button_cian:hover {
	background-color: $secondary_3;
}
