@import "../../../styles/variables";

.error {
    position: fixed;
    text-align: center;
    bottom: 30px;
    left: calc(50% - 150px);
    background-color: $error_color;
    box-shadow: 0 2px 1px #8f4a35;
    font-weight: 150;
    align-items: center;
    padding: 20px;
    width: 300px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    z-index: 9002;
    opacity: 0;
    visibility: hidden;
    transition: all 0.8s ease-in;
    &.show {
        opacity: 1;
        visibility: visible;
        transition: none;
    }
}
