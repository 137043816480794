@import "../../../styles/variables";

.messengerButton {
    position: relative;
    &:hover {
        cursor: pointer;
    }
    &__star {
        position: absolute;
        z-index: 9000;
        top: 2px;
        left: 2px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: $secondary_1;
        background: radial-gradient(circle, rgba(255, 255, 255, 1) 20%, $secondary_1 80%, transparent 100%);
        animation: anim-glow 2s ease infinite;
    }
}

@keyframes anim-glow {
    0% {
        box-shadow: 0 0 rgba($secondary_1, 1);
    }
    100% {
        box-shadow: 0 0 15px 15px transparent;
        border-width: 2px;
    }
}
