@import "./../../../styles/variables";

.wrapper {
    display: flex;
    position: relative;
    z-index: 1;
}

.sairus {
    font-size: $h2_size;
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    background-image: $gradient_2;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    &.small {
        font-size: $h3_size;
        line-height: 0.7;
    }
}

.underlineText {
    font-size: $h3_size;
    line-height: 1;
    font-weight: 150;
    margin-left: 5px;
    &.small {
        font-size: $h4_size;
        line-height: 0.7;
    }
}

@media (min-width: 1150px) {
    .sairus {
        font-size: $h1_size;
        &.small {
            font-size: $h2_size;
        }
    }
    .underlineText {
        font-size: $h2_size;
        line-height: 1;
        font-weight: 150;
        margin-left: 5px;
        &.small {
            font-size: $h3_size;
            line-height: 0.7;
        }
    }
}
