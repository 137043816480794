@import "../../styles/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.replenish {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    button {
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.list {
    margin-top: 40px;
    display: flex;
    gap: 45px;
    flex-wrap: wrap;
    justify-content: center;
}

.text {
    font-size: $h5_size;
    color: $text_2;
    font-weight: 150;
    margin-top: 10px;
}

.notice {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background: #071163;
    border-radius: 20px;
    text-align: left;
    margin-top: 30px;
    padding: 25px;
    font-weight: 150;
    font-size: $h5_size;

    > div {
        white-space: nowrap;
        text-align: center;
    }
    div div:first-child {
        margin-bottom: 15px;
    }
    div:first-child div:last-child {
        color: $secondary_1;
        font-weight: 500;
        margin-bottom: 20px;
    }
    div:last-child div:last-child {
        color: $secondary_3;
        font-weight: 500;
    }
}

.finish {
    position: relative;
    font-weight: 150;
    font-size: $h4_size;
    color: #ffffff;
    margin: 0 auto;
    padding: 12px 20px 12px 15px;
    border-radius: 24px;
    border: 1px solid $input_border;
    background-color: $input;
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    div {
        max-width: 85%;
        overflow: auto;
        &::-webkit-scrollbar {
            height: 0;
        }
    }
    span:last-child {
        flex-basis: 100%;
        margin-top: 10px;
        color: $secondary_3;
    }
    i {
        position: absolute;
        right: 10px;
        top: 9px;
    }
}

.error {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
    transition: all 0.1s ease-in;
    margin: 0;
    padding: 0;
    &.show {
        opacity: 1;
        transform: scaleY(1);
        visibility: visible;
        margin-top: 15px;
        margin-bottom: -15px;
        padding: 20px;
    }
}

.flexbox {
    align-self: stretch;
    display: flex;
    gap: 15px;
    align-items: stretch;
    justify-content: space-between;
    & > * {
        flex-basis: 50%;
    }
    &.amount {
        align-items: flex-end;
        margin-top: 40px;
    }
}

.amountText {
    text-align: center;
    font-size: $h5_size;
    font-weight: 150;
    margin-bottom: 15px;
}

.backWrapper {
    margin: 20px 0;
    position: relative;
    z-index: 5001px;
}

@media (min-width: 1150px) {
    .backWrapper {
        position: static;
    }
}

.successTitle {
    font-size: $h3_size;
    font-weight: 150;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.successText {
    font-size: $h4_size;
    font-weight: 150;
    margin-bottom: 20px;
    line-height: 1.2;
    text-align: center;
}

.link {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: $h5_size;
    z-index: 5001;
    margin: 10px 0;
    i {
        font-style: normal;
        text-decoration: none;
        filter: drop-shadow(0 0 2px white);
    }
    b {
        text-decoration: underline;
        font-weight: 150;
        color: $secondary_3;
    }
    &.back {
        i {
            margin-right: 10px;
        }
        @include media-breakpoint-up(md) {
            font-size: 18px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &.history {
        margin-bottom: 40px;
        i {
            margin-left: 10px;
        }
    }
}

.qr img {
    width: 150px;
}

@media (min-width: 750px) {
    .notice {
        flex-direction: row;
        justify-content: space-around;
        padding: 25px 45px 25px 25px;
        & > div {
            text-align-last: left;
        }
        div:first-child div:last-child {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 1150px) {
    .notice {
        justify-content: space-between;
    }
}

.copy {
    position: fixed;
    bottom: 30px;
    left: calc(50% - 125px);
    background-color: $secondary_3;
    box-shadow: 0 2px 1px #35468f;
    align-items: center;
    height: 40px;
    width: 250px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    z-index: 5001;
    opacity: 0;
    visibility: hidden;
    transition: all 0.8s ease-in;
    &.show {
        opacity: 1;
        visibility: visible;
        transition: none;
    }
}
