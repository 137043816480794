@import '../../../styles/variables';

.wrapper {
	display: flex;
	gap: 15px;
	@media (max-width: 1000px) {
		gap: 12px;
	}
	@media (max-width: 600px) {
		gap: 20px;
	}
}

.social_links:hover {
	background-color: $secondary_3;
}
