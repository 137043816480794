@use '../../../styles/variables';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../../styles/colors';
@import '../../../styles/icons';
@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/variables_new';

.accountHeader {
	grid-area: header;
	display: flex;
	gap: 10px;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
	@media (max-width: 1150px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
		padding: 15px;
	}
}

.logoContainer {
	min-width: 135px;
	display: flex;
	align-items: center;
	z-index: 1001;
	transition-delay: 0.3s;
	transition: position 0s;
	&.menuOpen {
		position: fixed;
		padding-right: 30px;
	}
	@media (max-width: 1150px) {
		width: 100%;
		justify-content: space-between;
	}
}

.menuButtons {
	display: flex;
	padding: 15px;
	gap: 45px;
	@media (min-width: 1151px) {
		display: none;
	}
}

.headerLogo {
	width: 60px;
	height: 60px;
	margin-left: 15px;
}
