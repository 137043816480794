@import "../../styles/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.withdraw {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.text {
    text-align: center;
    margin: 15px 0;
    font-size: $h5_size;
    font-weight: 150;
}

.list {
    margin-top: 40px;
    display: flex;
    gap: 45px;
    flex-wrap: wrap;
    justify-content: center;
}

.textField {
    align-self: center;
    max-width: 420px;
    width: 100%;
    margin: 25px 0 40px;
    i {
        background-color: $secondary_3;
    }
    input {
        font-size: 15px;
        font-weight: 400;
        width: 100%;
    }
    * {
        max-width: none !important;
    }
}

.error {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
    transition: all 0.1s ease-in;
    margin: 0;
    padding: 0;
    &.show {
        opacity: 1;
        transform: scaleY(1);
        visibility: visible;
        margin-bottom: 15px;
        padding: 20px;
    }
}

.flexbox {
    align-self: stretch;
    display: flex;
    gap: 15px;
    align-items: stretch;
    justify-content: space-between;
    & > * {
        flex-basis: 50%;
    }
    &.amount {
        align-items: flex-end;
    }
}

.amountText {
    text-align: center;
    font-size: $h5_size;
    font-weight: 150;
    margin-bottom: 15px;
}

.notice {
    display: flex;
    background: #ededed;
    border-radius: 10px;
    text-align: left;
    margin-top: 20px;

    > div {
        flex: 1;
        font-size: 18px;
        padding: 10px 25px;
        white-space: nowrap;

        @include media-breakpoint-up(md) {
            &:not(:last-child) {
                border-right: 1px solid #c4c4c4;
            }
        }
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
}

.finish {
    font-weight: 150;
    font-size: $h4_size;
    color: #ffffff;
    margin: 0 auto;
    padding: 12px 24px;
    border-radius: 24px;
    border: 1px solid $input_border;
    background-color: $input;
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    span:first-child {
        margin-right: 10px;
        color: $secondary_3;
    }
}

.successTitle {
    font-size: $h3_size;
    font-weight: 150;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}

.backWrapper {
    margin: 20px 0;
    position: relative;
    z-index: 5001px;
}

@media (min-width: 1150px) {
    .backWrapper {
        position: static;
    }
    .successTitle {
        margin-bottom: 40px;
    }
}

.link {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: $h5_size;
    z-index: 5001;
    margin: 10px 0;
    i {
        font-style: normal;
        text-decoration: none;
        filter: drop-shadow(0 0 2px white);
    }
    b {
        text-decoration: underline;
        font-weight: 150;
        color: $secondary_3;
    }
    &.back {
        i {
            margin-right: 10px;
        }
        @include media-breakpoint-up(md) {
            font-size: 18px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &.history {
        margin-bottom: 40px;
        i {
            margin-left: 10px;
        }
    }
}
