@import '../../styles/variables';

.wrapper {
	background-color: $primary_1;
	background-size: cover;
	background-position: center right;
	background-repeat: no-repeat;
	min-height: calc(100vh - 120px);
	margin-top: 120px;

	&.login {
		background-image: url(../../assets/img/jpg/login_page_375_bg.png);
	}
}

@media (min-width: 750px) {
	.wrapper {
		background-image: url(../../assets/img/jpg/login_page_750_bg.png);
		&.registration {
			background-size: contain;
			background-position: bottom right;
		}
	}
}

@media (min-width: 1150px) {
	.wrapper {
		margin-top: -25px;

		&.login {
			background-image: url(../../assets/img/jpg/login_page_bg.jpg);
		}
		&.registration {
			background-image: url(../../assets/img/jpg/reg_page_bg.jpg);
			background-position: center right;
			background-size: cover;
		}
	}

	.background {
		background-image: linear-gradient(transparent, 80%, rgba(0, 0, 0, 0.7));
		background-position: center bottom;
		// height: calc(100vh - 185px);
		height: 100%;
		padding-top: 185px;
		min-height: 100vh;
	}
}

@media (min-width: 1400px) {
	.background {
		background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
	}
}

.body {
	padding-bottom: 30px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
}

@media (min-width: 750px) {
	.body {
		padding-right: 25px;
		padding-left: 25px;
	}
}

@media (min-width: 1150px) {
	.body {
		padding: 0;
		padding-bottom: 40px;
		width: 485px;
	}
}

.title {
	align-self: center;
	text-align: center;
	font-weight: 150;
	font-size: $h3_size;
	line-height: 1.4;
	color: #ffffff;
	margin-bottom: 55px;
	max-width: 300px;
	display: none;
}
@media (min-width: 1150px) {
	.title {
		display: block;
	}
	.registration {
		.body {
			width: 730px;
		}
	}
}

.homeLink {
	margin-left: -25px;
	align-self: center;
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	text-transform: uppercase;
	.icon {
		width: 35px;
		transform: rotate(180deg);
		height: 25px;
	}
	span {
		font-size: $h5_size;
		font-weight: 500;
	}
}

@media (min-width: 1150px) {
	.homeLink {
		display: none;
	}
}
.footer__social {
	align-self: center;
}

.modalContent {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 40px 20px;
	gap: 20px;
	font-size: $h3_size;
	font-weight: 150;
}
