@import "../../../styles/variables";

.checkbox {
    display: flex;
    gap: 10px;
    position: relative;
}

.checkbox__title {
    color: $text_1;
}

.checkbox__label {
    color: $text_1;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    &:hover {
        cursor: pointer;
    }
}

.checkbox__label::before {
    content: "";
    display: inline-block;
    width: 34px;
    height: 34px;
    border-radius: 8px;
    border: 2px solid $input_border;
    background-color: $input;
    z-index: 9000;
}

.checkbox__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:checked + .checkbox__label::before {
        content: url("../../../assets/img/svg/mark.svg");
        background: radial-gradient($secondary_3 0%, $input 80%);
    }
}
