@import "../../../styles/variables";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 400px;
    position: relative;
    padding-top: 25px;
    p {
        position: relative;
        z-index: 2;
    }
    p:first-child {
        font-size: $h3_size;
        font-weight: 150;
        line-height: 1;
        margin-bottom: 15px;
    }
    p:last-child {
        font-size: 20px;
        line-height: 1.75;
    }
    &::before {
        position: absolute;
        top: 0;
        left: calc(50% - 75px);
        font-size: 133px;
        font-weight: 700;
        line-height: 0.7;
        z-index: 1;
        color: #002273;
    }
    &.step-1 {
        &::before {
            content: "01";
        }
    }
    &.step-2 {
        &::before {
            content: "02";
        }
    }
    &.step-3 {
        &::before {
            content: "03";
        }
    }
}
