@import './../../../styles/variables';

.menu {
	display: flex;
	justify-content: flex-end;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 5px;
	width: 620px;
	@media (max-width: 1280px) {
		width: 585px;
	}
}

.item {
	font-size: 17px;
	font-weight: 500;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: end;
	@media (max-width: 1280px) {
		font-size: 16px;
	}
	&:hover {
		color: $secondary_5;
	}
}
.item:not(:last-child) {
	margin-right: 15px;
}
.item:last-child {
	font-weight: 300;
}

@media (max-width: 1149px) {
	.menu {
		padding-top: 40px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		opacity: 0;
		visibility: hidden;
		align-items: center;
		position: fixed;
		top: 100px;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $primary_1;
		transform: translate(-100%);
		transition: all 0.2s ease-in;
		&.open {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			opacity: 1;
			visibility: visible;
			transform: translate(0);
			width: 100vw;
			overflow: auto;
		}
		.item {
			margin: 0;
			font-size: 18px;
		}
	}
}
