@import "../../styles/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.changeWallet {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    button {
        height: 48px;
        width: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.title {
    font-weight: 150;
    font-size: $h3_size;
    margin-top: 20px;
    margin-bottom: 30px;
}
.text {
    font-weight: 150;
    font-size: $h5_size;
}
.wallet {
    background: #f5f5f5;
    border-radius: 10px;
    font-weight: 600;
    font-size: 17px;
    line-height: 2;
    text-align: center;
    padding: 0 15px;
}

.link {
    margin-top: 30px;
    cursor: pointer;
    text-decoration-line: underline;
    font-weight: 150;
    text-underline-offset: 4px;
    display: flex;
    align-items: center;
    position: relative;
    left: -12px;
}

.textField {
    align-self: center;
    max-width: 420px;
    width: 100%;
    margin: 25px 0 40px;
    i {
        background-color: $secondary_3;
    }
    input {
        width: 100%;
        font-size: 15px;
        font-weight: 400;
    }
    * {
        max-width: none !important;
    }
}
