.demo {
    display: flex;
    justify-content: center;
    align-items: center;
}
#globe-3d,
#globe-2d-overlay,
#globe-popup-overlay,
.globe-popup {
    display: block;
    position: absolute;
}
#globe-2d-overlay,
#globe-popup-overlay {
    pointer-events: none;
}
#globe-3d {
    background-image: url(../assets/img/jpg/globus_bg.png);
    background-size: contain;
    background-repeat: no-repeat;
}
