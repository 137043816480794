@import "./../../../styles/variables";

.text {
    position: relative;
    z-index: 2;
    font-size: $h3_size;
    line-height: 1;
    font-weight: 150;
    margin-bottom: 10px;
    &.small {
        font-size: $h3_size;
    }
}

@media (min-width: 1150px) {
    .text {
        font-size: $h2_size;
        margin-bottom: 20px;
    }
}
