.container {
	margin: 0 15px;
}

@media (min-width: 1150px) {
	.container {
		margin: auto;
		max-width: 1010px;
	}
}

@media (min-width: 1400px) {
	.container {
		max-width: 1200px;
	}
}
