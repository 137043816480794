@import '../../../styles/variables';

.circleButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	width: 40px;
	height: 40px;
	background-color: transparent;
	border-radius: 100%;
	box-shadow: 0 0 5px 3px $secondary_3;
	transition: all 0.2s ease;
	position: relative;
	&:hover {
		cursor: pointer;
		transition: all 0.2s ease;
		background-color: $secondary_3;
	}
	@media (max-width: 850px) {
		width: 30px;
		height: 30px;
	}
	@media (max-width: 850px) {
		width: 35px;
		height: 35px;
	}
}

.circleButton__icon {
	width: 30px;
	height: 30px;
	background-color: $text_1;
	@media (max-width: 850px) {
		width: 25px;
		height: 25px;
	}
	@media (max-width: 600px) {
		width: 30px;
		height: 30px;
	}
}

.dot {
	position: absolute;
	width: 2px;
	height: 2px;
	left: 19px;
	background-color: #fff;
	border-radius: 50%;
}

.bold {
	width: 5px;
	height: 5px;
	left: 17.5px;
}

.dot_1 {
	top: -22px;
}

.dot_2 {
	top: -10px;
}

.dot_3 {
	bottom: -13px;
}

.dot_4 {
	bottom: -24px;
}
